html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $base-font;
  background-color: lighten($grey-bg, 9%);
}

#content {
  flex: 1 0 auto;
  padding: 1.5rem 2.5rem 1rem 2.5rem;
  max-width: 1900px;
  margin: auto;

  a, a:visited {
    color: $link-color;
    text-decoration: none;
  }

  a:hover, a:active {
    color: $link-hover;
  }

  a.btn, a:hover.btn, a:active.btn, a:visited.btn {
    color: #ffffff;
  }
}

.hidden {
  display: none;
}

.btn {
  display: inline-block;
  border: none;
  border-radius: 5px;
  padding: 0.7rem 1.5rem;
  background: lighten($link-color, 10%);
  font-size: 1rem;
  color: #ffffff;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.btn:hover,
.btn:focus {
    background: $link-color;
}

.btn:focus {
    outline: 1px solid #fff;
    outline-offset: -4px;
}

.btn:active {
    transform: scale(0.99);
}

.desktop {
  display: block;
}

@media screen and (max-width: $mobile-width){
  #content {
    padding: 1rem 1rem 0.5rem 1rem;
  }

  .desktop {
    display: none;
  }
}

#footer {
  flex-shrink: 0;

  a, a:visited {
    color: $link-color;
    text-decoration: none;
  }

  a:hover, a:active {
    color: $link-hover;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.centre-text {
  margin: auto;
}

h1 {
  font-size: 50px;
  font-weight: 400;
}

h2 {
  font-size: 30px;
  font-weight: 600;
}

p {
  font-size: $base-font-size;
  font-weight: 400;
  margin: 0.7rem 0;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}