$header-image: "/assets/img/beachdoc-header-image.jpg";
$mobile-width: 768px;

$base-font: "Roboto", sans-serif;
$base-font-size: 17px;
$font-color: black;

$link-color: #1e73be;
$link-hover: #333;

$grey-bg: darken(#efefef, 4%);
$grey-border: lighten(#d1d1d1, 5%);

@import "base";
@import "homepage";
@import "header";
@import "sections";
@import "page";
@import "photo-gallery";
@import "contact";
@import "glightbox";