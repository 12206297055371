.rental-info-box {
  text-align: center;
  padding: 0 3rem;

  h3 {
    margin-bottom: 0;
  }

  p {
    margin-top: 0.7rem;
  }
}

.header-box-link {
  color: $font-color;
  text-decoration: none;
  padding-bottom: 5px;

  .current-page {
    color: lighten($link-hover, 20%);
  }
}

.header-box-link:hover {
  background-color: lighten($grey-bg, 4.5%);

  .link-color {
    text-decoration: underline;
  }
}

.border-bottom {
  border-bottom: 1px solid $grey-border;
}

.rental-columns {
  display: grid;   
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding: 0 10%;
}

.link-color {
  color: $link-color;
}

.google_map {
  position: relative;
  text-align: right;
  height: 300px;
  width: 100%;
  border-top: 1px solid darken($grey-border, 10%);
  border-bottom: 1px solid darken($grey-border, 10%);
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 300px;
  width: 100%;
}

@media screen and (max-width: $mobile-width){
  .rental-info-box {
    padding: 0.3rem;

    h3 {
      margin: 0;
    }

    p {
      margin: 5px 0 0 0;
    }
  }

  .rental-title-mobile {
    font-size: 17px;
    font-weight: 400;
  }

  .rental-columns {
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    padding: 0 10px;
  }
}

@media screen and (max-width: 1600px){
  .rental-columns {
    padding: 10px;
  }
}