#footer {
  background-color: $grey-bg;
  padding: 0.5rem;
}

.grey-section {
  background-color: $grey-bg;
  padding: 1.2rem 0;
}

.page-title {
  margin: 0;
}

.page-subheading {
  margin: 0.3rem 0;
}

.main-content {
  margin-right: 20px;
  min-width: 400px;
}

.info-box {
  background-color: $grey-bg;
  padding: 5px 15px;
  border-radius: 5px;
  min-width: 200px;
}

.unit-info {
  color: lighten($font-color, 30%);

  div {
    border-bottom: 1px solid $grey-border;
  }
}

.spacing-small {
  margin: 0 -10px;

  div {
    margin: 10px;
  }
}

.unit-amenities {
  display: grid;
  grid: repeat(10, 30px) / auto-flow;
}

.amenity-icon {
  padding-left: 5px;
  font-size: 20px;
  color: lighten($font-color, 30%)
}

.amenities-notes {
  margin: 10px 0;

  p {
    font-size: 15px;
    color: lighten($font-color, 30%);
    margin: 2px 0;
  }
}

.display-list {
  font-size: 16px;
  font-weight: 400;
  color: lighten($font-color, 30%);
  cursor: pointer;
  display: none;
}

.amenity-list {
  display: block;
}

@media screen and (max-width: $mobile-width){
  .main-content {
    margin-right: 0;
  }

  .unit-amenities {
    grid: repeat(15, 30px) / auto-flow;
  }

  .display-list {
    display: inline;
  }

  .amenity-list {
    display: none;
  }
}

@media screen and (max-width: 500px){
  .main-content {
    min-width: 300px;
  }
}