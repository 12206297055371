$header-gradient: rgba(10,10,10,0.22);
$header-link-hover: #eaeaea;
$mobile-nav-bg: #222222;
$link-font-size: 17px;

#header {
  background-image: url($header-image);
  background-size: cover;
  background-image: linear-gradient(0deg, $header-gradient, $header-gradient), url($header-image);
  background-position: center center;
  background-repeat: no-repeat;
}

.hero-text {
  padding: 6rem 0;
  color: white;
  margin-bottom: 9rem; 
}

// Hide hamburger
#page-nav label, #hamburger {
  display: none;
}

.hamburger-padding {
  padding-right: 5px;
}

.menu-padding {
  padding-right: 35px;
}

// CSS for desktop navbar
#page-nav {
  ul {
    list-style: none;
    margin: 0;
    padding: 30px 50px 0 0;
    
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    justify-content: flex-end;
  }

  li {
    padding: 15px;
    font-size: $link-font-size;
    font-weight: 400;
    line-height: 1.5;
  }

  a, a:visited {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: $header-link-hover;
  }
}

// CSS for mobile navbar
@media screen and (max-width: $mobile-width){
  #page-nav {
    // For sticky navbars:
    // position: sticky;
    // top: 0;

    label {
      display: inline-block;
      color: white;
      background: $mobile-nav-bg;
      font-style: normal;
      font-size: $link-font-size;
      padding: 15px;
      width: 100%;
      cursor: pointer;
      text-align: center;
    }

    ul {
      padding: 0;
    }

    li {
      cursor: pointer;
      display: block;
      background-color: $mobile-nav-bg;
      border-top: 1px solid lighten($mobile-nav-bg, 7%);;
      text-align: center;
    }

    li:hover {
      background-color: lighten($mobile-nav-bg, 7%);
    }
  }

  // Toggle show/hide menu on checkbox click
  #page-nav ul {
    display: none;
  }
  #page-nav input:checked ~ ul {
    display: block;
  }

  .hero-text {
    padding: 3rem 0;
    margin-bottom: 0;
    font-size: 40px; 
  }
}