#contact-page {
  margin: auto;
  max-width: 800px;
}

#contact-form {
  font-family: $base-font;

  label {
    color: lighten($font-color, 30%);
    display: block;
    padding: 15px 0 5px 0;
  }

  input {
    height: 35px;
    width: 100%;
    font-family: $base-font;
    font-size: $base-font-size;
    font-weight: 400;
    color: $font-color;
  }

  textarea {
    padding: 10px 4px 10px 8px;
    font-family: $base-font;
    font-size: $base-font-size;
    font-weight: 400;
    color: $font-color;
    width: 100%;
  }

  button {
    display: inline-block;
    border: none;
    border-radius: 5px;
    padding: 1rem 2rem;
    margin: 15px 0;
    text-decoration: none;
    background: lighten($link-color, 10%);
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}

  button:hover,
  button:focus {
      background: $link-color;
  }

  button:focus {
      outline: 1px solid #fff;
      outline-offset: -4px;
  }

  button:active {
      transform: scale(0.99);
  }
}

@media screen and (max-width: $mobile-width){
  #contact-page {
    padding-right: 1rem;
  }
}