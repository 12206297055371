.rental-homepage {
  border: 1px solid $grey-border;
  padding: 15px;
  border-radius: 5px;
  background-color: lighten($grey-border, 10%);

  img {
    width: 300px;
    border: 2px solid darken($grey-bg, 10%);
  }
}

.homepage-grid {
  display: grid;  
  grid-gap: 20px;  
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); 
}

@media screen and (max-width: $mobile-width){
  .homepage-grid {
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}