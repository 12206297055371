.photo-gallery {
  min-width: 400px;
  
  img {
    border: 5px solid $grey-bg;
  }

  img:hover {
    cursor: pointer;
    opacity: 0.7;
    filter: alpha(opacity=70); /* for IE8 and earlier */
  }
}

.desktop-gallery {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;

  img {
    margin: 5px;
  }
}

.mobile-gallery {
  padding-right: 2rem;

  img {
    max-width: $mobile-width;
    width: 100%;
  }

  p {
    color: lighten($font-color, 20%);
    margin-top: 0;
    font-size: 15px;
  }
}

@media screen and (max-width: $mobile-width){
  .desktop-gallery {
    display: none;
  }

  .mobile-gallery {
    display: block;
  }
}

@media screen and (min-width: $mobile-width + 1){
  .mobile-gallery {
    display: none;
  }
}

@media screen and (max-width: 500px){
  .photo-gallery {
    min-width: 300px;
  }
}